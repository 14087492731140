import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { TdcInfo } from 'src/app/shared/models/user.model';
import { PasswordDataModel } from './password-data.model';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { FormComponent } from 'src/app/shared/components/globals/form/form.component';

@Component({
  selector: 'app-personal-data-account',
  templateUrl: './personal-data-account.component.html',
  styleUrl: './personal-data-account.component.css'
})
export class PersonalDataAccountComponent implements OnInit {

    // VIEW CHILD
    @ViewChild('formComponent', { static: true })
    public formComponent!: FormComponent;

    // INPUTS / OUTPUTS
    @Input()
    public userEmail!:     TdcInfo['email'] | undefined;

    @Output()
    public updateEmail:    EventEmitter<TdcInfo['email']['email_address']>  = new EventEmitter<TdcInfo['email']['email_address']>();

    @Output()
    public updatePassword: EventEmitter<PasswordDataModel['new']['values']> = new EventEmitter<PasswordDataModel['new']['values']>();

    // STATE
    protected title:         string  = 'Account Settings';
    protected resetPassword: boolean = false;

    // FORMS
    public accountEmailForm:  FormGroup<PasswordDataModel['account']['form']> = new FormGroup<PasswordDataModel['account']['form']>({
      email: new FormControl(null, [Validators.required, Validators.email]),
    })

    public resetPasswordForm: FormGroup<PasswordDataModel['reset']['form']>   = new FormGroup<PasswordDataModel['reset']['form']>({
      password:     new FormControl(null, Validators.required),
      new_password: new FormControl(null, Validators.required),
    })

    public newAccountForm:    FormGroup<PasswordDataModel['new']['form']>     = new FormGroup<PasswordDataModel['new']['form']>({
      email:        new FormControl(null, [Validators.required, Validators.email]),
      password:     new FormControl(null, Validators.required),
      new_password: new FormControl(null, Validators.required),
    })

    // GETTERS
    public get currentFormType(): 'email' | 'reset' | 'new' {
      if(!this.userEmail){
        return 'new';
      }else{
        return this.resetPassword ? 'reset' : 'email';
      }
    }

    public get currentForm(): FormGroup<PasswordDataModel['account']['form']> | FormGroup<PasswordDataModel['reset']['form']> | FormGroup<PasswordDataModel['new']['form']> {
      switch(this.currentFormType){
        case 'email':
          return this.accountEmailForm;
        case 'reset':
          return this.resetPasswordForm;
        case 'new':
          return this.newAccountForm;
      }
    }

    // LIFE CYCLE HOOKS
    ngOnInit(): void {
      this.initComponent();
    }

    // METHODS
    public toggleResetPassword(): void {
        
      this.resetPassword = !this.resetPassword;

      if(this.resetPassword){
        this.formComponent.canEdit.set(true);
      }else{
        this.formComponent.canEdit.set(false);
      }
    }

    public dismissChanges(form: 'email' | 'reset' | 'new' ): void {
      switch(form){
        case 'email':
          this.accountEmailForm.controls.email.setValue(this.userEmail?.email_address ?? '');
          break;
        case 'reset':
          this.resetPasswordForm.reset();
          break;
      }
    }

    public submitForm(form: 'email' | 'reset' ): void {
      switch(form){
        case 'email':
          console.log(this.accountEmailForm.value);
          break;
        case 'reset':
          console.log(this.resetPasswordForm.value);
          this.submitAccountEmail(this.accountEmailForm.controls.email.value!);
          break;
       
      }
    }

    public getNewAccountData(): {email: string | undefined , password: string | undefined } {
      
      const data = this.newAccountForm.getRawValue() as PasswordDataModel['new']['values'];
     
      return {
        email:    data.email ?? undefined , 
        password: data.password ?? undefined
      };
      
    }

    private initComponent(): void {
      
      // EMAIL ACCOUNT
      this.accountEmailForm.controls.email.setValue(this.userEmail?.email_address ?? '');

    }
    
    private submitAccountEmail(email:string): void {
      this.updateEmail.emit(email);
    }

}
