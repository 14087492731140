<app-form
    [title]="title"
    [showButtons]="userData ? true : false"
    [form]="personalDataForm"
    (dismiss)="dismissChanges()"
>    
    
    <form form id="personalData" [formGroup]="personalDataForm" (ngSubmit)="submitForm()" class="form">

        <!-- PERSONAL DATA -->
        <div class=" grid grid-cols-1 lg:grid-cols-3 grid-rows-2 gap-4">

        <!-- NAME -->
        <div class="flex flex-col">
            <label class="text-main font-semibold font-main mb-1" for="firstName">
                First Name
            </label>
            <input class="input" type="text" formControlName="firstName" id="firstName" />
        </div>

        <!-- LAST NAME -->
        <div class="flex flex-col">
            <label class="text-main font-semibold font-main mb-1" for="lastName">
                Last Name
            </label>
            <input type="text" formControlName="lastName" id="lastName" />
        </div>

        <!-- GENDER -->
        <div class="flex flex-col">
            <label class="text-main font-semibold font-main mb-1" for="gender">
                Gender
            </label>
            <select name="gender" id="gender" formControlName="gender">
                <option value="" disabled selected>Please select one</option>
                <option value="MALE">
                    Male
                </option>
                <option value="FEMALE">
                    Female
                </option>
                <option value="NOT_LISTED">
                    Not Listed
                </option>
                <option value="NOT_ANSWER">
                    Prefer not to answer
                </option>
            </select>
        </div>

        <!-- BIRTH DATE -->
        <div class="flex flex-col">
            <label class="text-main font-semibold font-main mb-1" for="birthDate">
                Date of Birth
            </label>
            <input type="date" formControlName="birthDate" id="birthDate" />
        </div>

        <!-- EMAIL -->
        <div class="flex flex-col">
            <label class="text-main font-semibold font-main mb-1" for="phoneNumber">
                Primary Phone Number
            </label>
            <input type="text" formControlName="phoneNumber" id="phoneNumber" />
        </div>
        </div>

        <!-- ADRESS DATA -->
        <div class="grid grid-cols-1 lg:grid-cols-3 grid-rows-2 gap-4 mt-5">

        <!-- ADRESS LINE 1 -->
        <div class="flex flex-col">
            <label class="text-main font-semibold font-main mb-1" for="address">
                Address Line 1
            </label>    
            <input type="text" formControlName="address" id="address" />
        </div>

        <!-- ADRESS LINE 2 -->
        <div class="flex flex-col">
            <label class="text-main font-semibold font-main mb-1" for="address2">
                Address Line 2
            </label>
            <input type="text" formControlName="address2" id="address2" />
        </div>

        <!-- ZIP CODE -->
        <div class="flex flex-col">
            <label class="text-main font-semibold font-main mb-1" for="zipCode">
                Postal Code (ZIP)
            </label>
            <input type="text" formControlName="zipCode" id="zipCode" />
        </div>

        <!-- COUNTRY -->
        <div class="flex flex-col">
            <label class="text-main font-semibold font-main mb-1" for="country">
                Country
            </label>
            <select class="countrySelector" formControlName="country" name="country" id="country">
                <option *ngFor="let country of countryData.countries" [value]="country.icaoCode">
                    {{ country.name }}
                </option>
            </select>
        </div>

        <!-- STATE -->
        <div class="flex flex-col">
            <label class="text-main font-semibold font-main mb-1" for="state">
                State / Province
            </label>
            
            <ng-container *ngIf="!countryData.hasSubcountries else subcountrySelector">
                <input type="text" formControlName="state" id="state"/>
            </ng-container>
            <ng-template #subcountrySelector>
                <select class="countrySelector" formControlName="state" name="state" id="state" >
                    <option value="" disabled selected>Please select one</option>
                    <option *ngFor="let subcountry of countryData.availableSubcountries" [value]="subcountry.sub_country_code">
                        {{ subcountry.sub_country_name }}
                    </option>
                </select>
            </ng-template>
        </div>

        <!-- CITY -->
        <div class="flex flex-col">
            <label class="text-main font-semibold font-main mb-1" for="city">
                City
            </label>
            <input type="text" formControlName="city" id="city"/>

        </div>

        </div>

    </form>
    
</app-form>
