<div class="px-3 lg:py-5 lg:px-0 xl:max-w-6xl mx-auto">

   <app-form
    [title]="templateVars.title"
    [form]="dataProtectionForm"
    [showButtons]="dataProtection ? true : false"
    (dismiss)="dismissChanges()"
   >

    <div form>
        
        <!-- TEXT 1 -->
        <p class="text-main" [innerHTML]="templateVars.text1"></p>

        <!-- FORM -->
        <form [formGroup]="dataProtectionForm" class="my-5 grid grid-cols-2 gap-y-2 align-middle md:flex md:max-w-xl md:justify-between">

            <div class="flex flex-row-reverse justify-center" *ngFor="let contactMethods of dataProtectionForm.controls | keyvalue ">
                
                <label [for]="contactMethods.key" class="ml-3 w-2/4">
                    {{contactMethods.key | titlecase}}
                </label>

                <input type="checkbox" class="radio" [formControlName]="contactMethods.key" [id]="'data_'+ contactMethods.key" [value]="contactMethods.value.value">
                
            </div>
            
        </form>

        <!-- TEXT 2 -->
        <p class="text-main" [innerHTML]="templateVars.text2"></p>
        
    </div>

   </app-form>

</div>