<section>

    <ng-container *ngIf="userData || type === 'register'">
        
        <!-- FORM -->
        <app-personal-data-form
            [userData]="userData"
        />
    
        <!-- ACCOUNT SETTINGS -->
        <app-personal-data-account
            [userEmail]="userEmail"
        />
    
        <!-- DATA PROTECTION -->
        <app-personal-data-protection
            [dataProtection]="userDataProtection"
        />
        
    </ng-container>

</section>