[{
  "code": "GB",
  "icaoCode": "GBR",
  "name": "United Kingdom",
  "phoneCode": "44",
  "schengen": true
},
{
  "code": "AF",
  "icaoCode": "AFG",
  "name": "Afghanistan",
  "phoneCode": "93",
  "schengen": false
},
{
  "code": "AL",
  "icaoCode": "ALB",
  "name": "Albania",
  "phoneCode": "355",
  "schengen": false
},
{
  "code": "DZ",
  "icaoCode": "DZA",
  "name": "Algeria",
  "phoneCode": "213",
  "schengen": false
},
{
  "code": "AS",
  "icaoCode": "ASM",
  "name": "American Samoa",
  "phoneCode": "684",
  "schengen": false
},
{
  "code": "AD",
  "icaoCode": "AND",
  "name": "Andorra",
  "phoneCode": "376",
  "schengen": false
},
{
  "code": "AO",
  "icaoCode": "AGO",
  "name": "Angola",
  "phoneCode": "244",
  "schengen": false
},
{
  "code": "AI",
  "icaoCode": "AIA",
  "name": "Anguilla",
  "phoneCode": "264",
  "schengen": false
},
{
  "code": "AG",
  "icaoCode": "ATG",
  "name": "Antigua and Barbuda",
  "phoneCode": "268",
  "schengen": false
},
{
  "code": "AR",
  "icaoCode": "ARG",
  "name": "Argentina",
  "phoneCode": "54",
  "schengen": false
},
{
  "code": "AM",
  "icaoCode": "ARM",
  "name": "Armenia",
  "phoneCode": "374",
  "schengen": false
},
{
  "code": "AW",
  "icaoCode": "ABW",
  "name": "Aruba",
  "phoneCode": "297",
  "schengen": false
},
{
  "code": "AU",
  "icaoCode": "AUS",
  "name": "Australia",
  "phoneCode": "61",
  "schengen": false
},
{
  "code": "AT",
  "icaoCode": "AUT",
  "name": "Austria",
  "phoneCode": "43",
  "schengen": true
},
{
  "code": "AZ",
  "icaoCode": "AZE",
  "name": "Azerbaijan",
  "phoneCode": "994",
  "schengen": false
},
{
  "code": "BS",
  "icaoCode": "BHS",
  "name": "Bahamas",
  "phoneCode": "242",
  "schengen": false
},
{
  "code": "BH",
  "icaoCode": "BHR",
  "name": "Bahrain",
  "phoneCode": "973",
  "schengen": false
},
{
  "code": "BD",
  "icaoCode": "BGD",
  "name": "Bangladesh",
  "phoneCode": "880",
  "schengen": false
},
{
  "code": "BB",
  "icaoCode": "BRB",
  "name": "Barbados",
  "phoneCode": "246",
  "schengen": false
},
{
  "code": "BY",
  "icaoCode": "BLR",
  "name": "Belarus",
  "phoneCode": "375",
  "schengen": false
},
{
  "code": "BE",
  "icaoCode": "BEL",
  "name": "Belgium",
  "phoneCode": "32",
  "schengen": true
},
{
  "code": "BZ",
  "icaoCode": "BLZ",
  "name": "Belize",
  "phoneCode": "501",
  "schengen": false
},
{
  "code": "BJ",
  "icaoCode": "BEN",
  "name": "Benin",
  "phoneCode": "229",
  "schengen": false
},
{
  "code": "BM",
  "icaoCode": "BMU",
  "name": "Bermuda",
  "phoneCode": "441",
  "schengen": false
},
{
  "code": "BT",
  "icaoCode": "BTN",
  "name": "Bhutan",
  "phoneCode": "975",
  "schengen": false
},
{
  "code": "BO",
  "icaoCode": "BOL",
  "name": "Bolivia",
  "phoneCode": "591",
  "schengen": false
},
{
  "code": "BA",
  "icaoCode": "BIH",
  "name": "Bosnia and Herzegovina",
  "phoneCode": "387",
  "schengen": false
},
{
  "code": "BW",
  "icaoCode": "BWA",
  "name": "Botswana",
  "phoneCode": "267",
  "schengen": false
},
{
  "code": "BR",
  "icaoCode": "BRA",
  "name": "Brazil",
  "phoneCode": "55",
  "schengen": false
},
{
  "code": "VG",
  "name": "British Virgin Islands",
  "icaoCode": "VGB",
  "phoneCode": "1",
  "schengen": false
},
{
  "code": "BN",
  "icaoCode": "BRN",
  "name": "Brunei",
  "phoneCode": "673",
  "schengen": false
},
{
  "code": "BG",
  "icaoCode": "BGR",
  "name": "Bulgaria",
  "phoneCode": "359",
  "schengen": true
},
{
  "code": "BF",
  "icaoCode": "BFA",
  "name": "Burkina Faso",
  "phoneCode": "226",
  "schengen": false
},
{
  "code": "BI",
  "icaoCode": "BDI",
  "name": "Burundi",
  "phoneCode": "257",
  "schengen": false
},
{
  "code": "KH",
  "icaoCode": "KHM",
  "name": "Cambodia",
  "phoneCode": "855",
  "schengen": false
},
{
  "code": "CM",
  "icaoCode": "CMR",
  "name": "Cameroon",
  "phoneCode": "237",
  "schengen": false
},
{
  "code": "CA",
  "icaoCode": "CAN",
  "name": "Canada",
  "phoneCode": "1",
  "schengen": false
},
{
  "code": "CV",
  "icaoCode": "CPV",
  "name": "Cape Verde",
  "phoneCode": "238",
  "schengen": false
},
{
  "code": "KY",
  "icaoCode": "CYM",
  "name": "Cayman Islands",
  "phoneCode": "345",
  "schengen": false
},
{
  "code": "CF",
  "icaoCode": "CAF",
  "name": "Central African Republic",
  "phoneCode": "236",
  "schengen": false
},
{
  "code": "TD",
  "icaoCode": "TCD",
  "name": "Chad",
  "phoneCode": "235",
  "schengen": false
},
{
  "code": "CL",
  "icaoCode": "CHL",
  "name": "Chile",
  "phoneCode": "56",
  "schengen": false
},
{
  "code": "CN",
  "icaoCode": "CHN",
  "name": "China",
  "phoneCode": "86",
  "schengen": false
},
{
  "code": "CX",
  "icaoCode": "CXR",
  "name": "Christmas Island (Indian Ocean)",
  "phoneCode": "53",
  "schengen": false
},
{
  "code": "CO",
  "icaoCode": "COL",
  "name": "Colombia",
  "phoneCode": "57",
  "schengen": false
},
{
  "code": "KM",
  "icaoCode": "COM",
  "name": "Comoros",
  "phoneCode": "269",
  "schengen": false
},
{
  "code": "CG",
  "icaoCode": "COG",
  "name": "Congo",
  "phoneCode": "242",
  "schengen": false
},
{
  "code": "CK",
  "icaoCode": "COK",
  "name": "Cook Islands",
  "phoneCode": "682",
  "schengen": false
},
{
  "code": "CR",
  "icaoCode": "CRI",
  "name": "Costa Rica",
  "phoneCode": "506",
  "schengen": false
},
{
  "code": "HR",
  "icaoCode": "HRV",
  "name": "Croatia",
  "phoneCode": "385",
  "schengen": true
},
{
  "code": "CU",
  "icaoCode": "CUB",
  "name": "Cuba",
  "phoneCode": "53",
  "schengen": false
},
{
  "code": "CY",
  "icaoCode": "CYP",
  "name": "Cyprus",
  "phoneCode": "357",
  "schengen": true
},
{
  "code": "CZ",
  "icaoCode": "CZE",
  "name": "Czech Republic",
  "phoneCode": "420",
  "schengen": true
},
{
  "code": "CD",
  "icaoCode": "COD",
  "name": "Democratic Republic of the Congo",
  "phoneCode": "243",
  "schengen": false
},
{
  "code": "DK",
  "icaoCode": "DNK",
  "name": "Denmark",
  "phoneCode": "45",
  "schengen": true
},
{
  "code": "DJ",
  "icaoCode": "DJI",
  "name": "Djibouti",
  "phoneCode": "253",
  "schengen": false
},
{
  "code": "DM",
  "icaoCode": "DMA",
  "name": "Dominica",
  "phoneCode": "767",
  "schengen": false
},
{
  "code": "DO",
  "icaoCode": "DOM",
  "name": "Dominican Republic",
  "phoneCode": "829",
  "schengen": false
},
{
  "code": "EC",
  "icaoCode": "ECU",
  "name": "Ecuador",
  "phoneCode": "593",
  "schengen": false
},
{
  "code": "EG",
  "icaoCode": "EGY",
  "name": "Egypt",
  "phoneCode": "20",
  "schengen": false
},
{
  "code": "SV",
  "icaoCode": "SLV",
  "name": "El Salvador",
  "phoneCode": "503",
  "schengen": false
},
{
  "code": "GQ",
  "icaoCode": "GNQ",
  "name": "Equatorial Guinea",
  "phoneCode": "240",
  "schengen": false
},
{
  "code": "ER",
  "icaoCode": "ERI",
  "name": "Eritrea",
  "phoneCode": "291",
  "schengen": false
},
{
  "code": "EE",
  "icaoCode": "EST",
  "name": "Estonia",
  "phoneCode": "372",
  "schengen": true
},
{
  "code": "ET",
  "icaoCode": "ETH",
  "name": "Ethiopia",
  "phoneCode": "251",
  "schengen": false
},
{
  "code": "FK",
  "icaoCode": "FLK",
  "name": "Falkland Islands",
  "phoneCode": "500",
  "schengen": false
},
{
  "code": "FO",
  "icaoCode": "FRO",
  "name": "Faroe Islands",
  "phoneCode": "298",
  "schengen": false
},
{
  "code": "FM",
  "icaoCode": "FSM",
  "name": "Federated States of Micronesia",
  "phoneCode": "691",
  "schengen": false
},
{
  "code": "FJ",
  "icaoCode": "FJI",
  "name": "Fiji",
  "phoneCode": "679",
  "schengen": false
},
{
  "code": "FI",
  "icaoCode": "FIN",
  "name": "Finland",
  "phoneCode": "358",
  "schengen": true
},
{
  "code": "FR",
  "icaoCode": "FRA",
  "name": "France",
  "phoneCode": "33",
  "schengen": true
},
{
  "code": "GF",
  "icaoCode": "GUF",
  "name": "French Guyana",
  "phoneCode": "594",
  "schengen": false
},
{
  "code": "PF",
  "icaoCode": "PYF",
  "name": "French Polynesia",
  "phoneCode": "689",
  "schengen": false
},
{
  "code": "GA",
  "icaoCode": "GAB",
  "name": "Gabon",
  "phoneCode": "241",
  "schengen": false
},
{
  "code": "GM",
  "icaoCode": "GMB",
  "name": "Gambia",
  "phoneCode": "220",
  "schengen": false
},
{
  "code": "GE",
  "icaoCode": "GEO",
  "name": "Georgia",
  "phoneCode": "995",
  "schengen": false
},
{
  "code": "DE",
  "icaoCode": "DEU",
  "name": "Germany",
  "phoneCode": "49",
  "schengen": true
},
{
  "code": "GH",
  "icaoCode": "GHA",
  "name": "Ghana",
  "phoneCode": "233",
  "schengen": false
},
{
  "code": "GI",
  "icaoCode": "GIB",
  "name": "Gibraltar",
  "phoneCode": "350",
  "schengen": false
},
{
  "code": "GR",
  "icaoCode": "GRC",
  "name": "Greece",
  "phoneCode": "30",
  "schengen": true
},
{
  "code": "GL",
  "icaoCode": "GRL",
  "name": "Greenland",
  "phoneCode": "299",
  "schengen": false
},
{
  "code": "GD",
  "icaoCode": "GRD",
  "name": "Grenada",
  "phoneCode": "473",
  "schengen": false
},
{
  "code": "GP",
  "icaoCode": "GLP",
  "name": "Guadeloupe",
  "phoneCode": "590",
  "schengen": false
},
{
  "code": "GU",
  "icaoCode": "GUM",
  "name": "Guam",
  "phoneCode": "671",
  "schengen": false
},
{
  "code": "GT",
  "icaoCode": "GTM",
  "name": "Guatemala",
  "phoneCode": "502",
  "schengen": false
},
{
  "code": "GN",
  "icaoCode": "GIN",
  "name": "Guinea",
  "phoneCode": "224",
  "schengen": false
},
{
  "code": "GW",
  "icaoCode": "GNB",
  "name": "Guinea-Bissau",
  "phoneCode": "245",
  "schengen": false
},
{
  "code": "GY",
  "icaoCode": "GUY",
  "name": "Guyana",
  "phoneCode": "592",
  "schengen": false
},
{
  "code": "HT",
  "icaoCode": "HTI",
  "name": "Haiti",
  "phoneCode": "509",
  "schengen": false
},
{
  "code": "HN",
  "icaoCode": "HND",
  "name": "Honduras",
  "phoneCode": "504",
  "schengen": false
},
{
  "code": "HK",
  "icaoCode": "HKG",
  "name": "Hong Kong",
  "phoneCode": "852",
  "schengen": false
},
{
  "code": "HU",
  "icaoCode": "HUN",
  "name": "Hungary",
  "phoneCode": "36",
  "schengen": true
},
{
  "code": "IS",
  "icaoCode": "ISL",
  "name": "Iceland",
  "phoneCode": "354",
  "schengen": true
},
{
  "code": "IN",
  "icaoCode": "IND",
  "name": "India",
  "phoneCode": "91",
  "schengen": false
},
{
  "code": "ID",
  "icaoCode": "IDN",
  "name": "Indonesia",
  "phoneCode": "62",
  "schengen": false
},
{
  "code": "IR",
  "icaoCode": "IRN",
  "name": "Iran",
  "phoneCode": "98",
  "schengen": false
},
{
  "code": "IQ",
  "icaoCode": "IRQ",
  "name": "Iraq",
  "phoneCode": "964",
  "schengen": false
},
{
  "code": "IE",
  "icaoCode": "IRL",
  "name": "Ireland",
  "phoneCode": "353",
  "schengen": false
},
{
  "code": "IL",
  "icaoCode": "ISR",
  "name": "Israel",
  "phoneCode": "972",
  "schengen": false
},
{
  "code": "IT",
  "icaoCode": "ITA",
  "name": "Italy",
  "phoneCode": "39",
  "schengen": true
},
{
  "code": "CI",
  "icaoCode": "CIV",
  "name": "Ivory Coast",
  "phoneCode": "225",
  "schengen": false
},
{
  "code": "JM",
  "icaoCode": "JAM",
  "name": "Jamaica",
  "phoneCode": "876",
  "schengen": false
},
{
  "code": "JP",
  "icaoCode": "JPN",
  "name": "Japan",
  "phoneCode": "81",
  "schengen": false
},
{
  "code": "JO",
  "icaoCode": "JOR",
  "name": "Jordan",
  "phoneCode": "962",
  "schengen": false
},
{
  "code": "KZ",
  "icaoCode": "KAZ",
  "name": "Kazakhstan",
  "phoneCode": "7",
  "schengen": false
},
{
  "code": "KE",
  "icaoCode": "KEN",
  "name": "Kenya",
  "phoneCode": "254",
  "schengen": false
},
{
  "code": "KI",
  "icaoCode": "KIR",
  "name": "Kiribati",
  "phoneCode": "686",
  "schengen": false
},
{
  "code": "XK",
  "icaoCode": "RKS",
  "name": "Kosovo",
  "phoneCode": "383",
  "schengen": false
},
{
  "code": "KW",
  "icaoCode": "KWT",
  "name": "Kuwait",
  "phoneCode": "965",
  "schengen": false
},
{
  "code": "KG",
  "icaoCode": "KGZ",
  "name": "Kyrgyzstan",
  "phoneCode": "996",
  "schengen": false
},
{
  "code": "LA",
  "icaoCode": "LAO",
  "name": "Laos",
  "phoneCode": "856",
  "schengen": false
},
{
  "code": "LV",
  "icaoCode": "LVA",
  "name": "Latvia",
  "phoneCode": "371",
  "schengen": true
},
{
  "code": "LB",
  "icaoCode": "LBN",
  "name": "Lebanon",
  "phoneCode": "961",
  "schengen": false
},
{
  "code": "LS",
  "icaoCode": "LSO",
  "name": "Lesotho",
  "phoneCode": "266",
  "schengen": false
},
{
  "code": "LR",
  "icaoCode": "LBR",
  "name": "Liberia",
  "phoneCode": "231",
  "schengen": false
},
{
  "code": "LY",
  "icaoCode": "LBY",
  "name": "Libya",
  "phoneCode": "218",
  "schengen": false
},
{
  "code": "LI",
  "icaoCode": "LIE",
  "name": "Liechtenstein",
  "phoneCode": "423",
  "schengen": true
},
{
  "code": "LT",
  "icaoCode": "LTU",
  "name": "Lithuania",
  "phoneCode": "370",
  "schengen": true
},
{
  "code": "LU",
  "icaoCode": "LUX",
  "name": "Luxembourg",
  "phoneCode": "352",
  "schengen": true
},
{
  "code": "MO",
  "name": "Macao (SAR, China)",
  "icaoCode": "MAC",
  "phoneCode": "853",
  "schengen": false
},
{
  "code": "MK",
  "icaoCode": "MKD",
  "name": "Macedonia",
  "phoneCode": "389",
  "schengen": false
},
{
  "code": "MG",
  "icaoCode": "MDG",
  "name": "Madagascar",
  "phoneCode": "261",
  "schengen": false
},
{
  "code": "MW",
  "icaoCode": "MWI",
  "name": "Malawi",
  "phoneCode": "265",
  "schengen": false
},
{
  "code": "MY",
  "icaoCode": "MYS",
  "name": "Malaysia",
  "phoneCode": "60",
  "schengen": false
},
{
  "code": "MV",
  "icaoCode": "MDV",
  "name": "Maldives",
  "phoneCode": "960",
  "schengen": false
},
{
  "code": "ML",
  "icaoCode": "MLI",
  "name": "Mali",
  "phoneCode": "223",
  "schengen": false
},
{
  "code": "MT",
  "icaoCode": "MLT",
  "name": "Malta",
  "phoneCode": "356",
  "schengen": true
},
{
  "code": "MH",
  "icaoCode": "MHL",
  "name": "Marshall Islands",
  "phoneCode": "692",
  "schengen": false
},
{
  "code": "MQ",
  "icaoCode": "MTQ",
  "name": "Martinique",
  "phoneCode": "596",
  "schengen": false
},
{
  "code": "MR",
  "icaoCode": "MRT",
  "name": "Mauritania",
  "phoneCode": "222",
  "schengen": false
},
{
  "code": "MU",
  "icaoCode": "MUS",
  "name": "Mauritius",
  "phoneCode": "230",
  "schengen": false
},
{
  "code": "MX",
  "icaoCode": "MEX",
  "name": "Mexico",
  "phoneCode": "52",
  "schengen": false
},
{
  "code": "MD",
  "icaoCode": "MDA",
  "name": "Moldova",
  "phoneCode": "373",
  "schengen": false
},
{
  "code": "MC",
  "icaoCode": "MCO",
  "name": "Monaco",
  "phoneCode": "377",
  "schengen": false
},
{
  "code": "MN",
  "icaoCode": "MNG",
  "name": "Mongolia",
  "phoneCode": "976",
  "schengen": false
},
{
  "code": "ME",
  "icaoCode": "MNE",
  "name": "Montenegro",
  "phoneCode": "382",
  "schengen": false
},
{
  "code": "MS",
  "icaoCode": "MSR",
  "name": "Montserrat",
  "phoneCode": "664",
  "schengen": false
},
{
  "code": "MA",
  "icaoCode": "MAR",
  "name": "Morocco",
  "phoneCode": "212",
  "schengen": false
},
{
  "code": "MZ",
  "icaoCode": "MOZ",
  "name": "Mozambique",
  "phoneCode": "258",
  "schengen": false
},
{
  "code": "MM",
  "icaoCode": "MMR",
  "name": "Myanmar",
  "phoneCode": "95",
  "schengen": false
},
{
  "code": "NA",
  "icaoCode": "NAM",
  "name": "Namibia",
  "phoneCode": "264",
  "schengen": false
},
{
  "code": "NR",
  "icaoCode": "NRU",
  "name": "Nauru",
  "phoneCode": "674",
  "schengen": false
},
{
  "code": "NP",
  "icaoCode": "NPL",
  "name": "Nepal",
  "phoneCode": "977",
  "schengen": false
},
{
  "code": "NL",
  "icaoCode": "NLD",
  "name": "Netherlands",
  "phoneCode": "31",
  "schengen": true
},
{
  "code": "AN",
  "icaoCode": "ANT",
  "name": "Netherlands Antilles",
  "phoneCode": "599",
  "schengen": false
},
{
  "code": "NC",
  "icaoCode": "NCL",
  "name": "New Caledonia",
  "phoneCode": "687",
  "schengen": false
},
{
  "code": "NZ",
  "icaoCode": "NZL",
  "name": "New Zealand",
  "phoneCode": "64",
  "schengen": false
},
{
  "code": "NI",
  "icaoCode": "NIC",
  "name": "Nicaragua",
  "phoneCode": "505",
  "schengen": false
},
{
  "code": "NE",
  "icaoCode": "NER",
  "name": "Niger",
  "phoneCode": "227",
  "schengen": false
},
{
  "code": "NG",
  "icaoCode": "NGA",
  "name": "Nigeria",
  "phoneCode": "234",
  "schengen": false
},
{
  "code": "NF",
  "icaoCode": "NFK",
  "name": "Norfolk Island",
  "phoneCode": "672",
  "schengen": false
},
{
  "code": "KP",
  "icaoCode": "PRK",
  "name": "North Korea",
  "phoneCode": "850",
  "schengen": false
},
{
  "code": "MP",
  "icaoCode": "MNP",
  "name": "Northern Mariana Islands",
  "phoneCode": "670",
  "schengen": false
},
{
  "code": "NO",
  "icaoCode": "NOR",
  "name": "Norway",
  "phoneCode": "47",
  "schengen": true
},
{
  "code": "OM",
  "icaoCode": "OMN",
  "name": "Oman",
  "phoneCode": "968",
  "schengen": false
},
{
  "code": "PK",
  "icaoCode": "PAK",
  "name": "Pakistan",
  "phoneCode": "92",
  "schengen": false
},
{
  "code": "PS",
  "icaoCode": "PSE",
  "name": "Palastine",
  "phoneCode": "970",
  "schengen": false
},
{
  "code": "PW",
  "icaoCode": "PLW",
  "name": "Palau",
  "phoneCode": "680",
  "schengen": false
},
{
  "code": "PA",
  "icaoCode": "PAN",
  "name": "Panama",
  "phoneCode": "507",
  "schengen": false
},
{
  "code": "PG",
  "icaoCode": "PNG",
  "name": "Papua New Guinea",
  "phoneCode": "675",
  "schengen": false
},
{
  "code": "PY",
  "icaoCode": "PRY",
  "name": "Paraguay",
  "phoneCode": "595",
  "schengen": false
},
{
  "code": "PE",
  "icaoCode": "PER",
  "name": "Peru",
  "phoneCode": "51",
  "schengen": false
},
{
  "code": "PH",
  "icaoCode": "PHL",
  "name": "Philippines",
  "phoneCode": "63",
  "schengen": false
},
{
  "code": "PL",
  "icaoCode": "POL",
  "name": "Poland",
  "phoneCode": "48",
  "schengen": true
},
{
  "code": "PT",
  "icaoCode": "PRT",
  "name": "Portugal",
  "phoneCode": "351",
  "schengen": true
},
{
  "code": "PR",
  "icaoCode": "PRI",
  "name": "Puerto Rico",
  "phoneCode": "787",
  "schengen": false
},
{
  "code": "QA",
  "icaoCode": "QAT",
  "name": "Qatar",
  "phoneCode": "974",
  "schengen": false
},
{
  "code": "RE",
  "icaoCode": "REU",
  "name": "Reunion",
  "phoneCode": "262",
  "schengen": false
},
{
  "code": "RO",
  "icaoCode": "ROU",
  "name": "Romania",
  "phoneCode": "40",
  "schengen": true
},
{
  "code": "RU",
  "icaoCode": "RUS",
  "name": "Russian Federation",
  "phoneCode": "7",
  "schengen": false
},
{
  "code": "RW",
  "icaoCode": "RWA",
  "name": "Rwanda",
  "phoneCode": "250",
  "schengen": false
},
{
  "code": "SH",
  "icaoCode": "SHN",
  "name": "Saint Helena",
  "phoneCode": "290",
  "schengen": false
},
{
  "code": "KN",
  "icaoCode": "KNA",
  "name": "Saint Kitts and Nevis",
  "phoneCode": "869",
  "schengen": false
},
{
  "code": "LC",
  "icaoCode": "SLU",
  "name": "Saint Lucia",
  "phoneCode": "758",
  "schengen": false
},
{
  "code": "PM",
  "icaoCode": "SPM",
  "name": "Saint Pierre and Miquelon",
  "phoneCode": "508",
  "schengen": false
},
{
  "code": "VC",
  "icaoCode": "VCT",
  "name": "Saint Vincent and the Grenadines",
  "phoneCode": "784",
  "schengen": false
},
{
  "code": "WS",
  "icaoCode": "WSM",
  "name": "Samoa",
  "phoneCode": "685",
  "schengen": false
},
{
  "code": "SM",
  "icaoCode": "SMR",
  "name": "San Marino",
  "phoneCode": "378",
  "schengen": false
},
{
  "code": "ST",
  "icaoCode": "STP",
  "name": "Sao Tome and Principe",
  "phoneCode": "239",
  "schengen": false
},
{
  "code": "SA",
  "icaoCode": "SAU",
  "name": "Saudi Arabia",
  "phoneCode": "966",
  "schengen": false
},
{
  "code": "SN",
  "icaoCode": "SEN",
  "name": "Senegal",
  "phoneCode": "221",
  "schengen": false
},
{
  "code": "RS",
  "icaoCode": "SRB",
  "name": "Serbia",
  "phoneCode": "381",
  "schengen": false
},
{
  "code": "SC",
  "icaoCode": "SYC",
  "name": "Seychelles",
  "phoneCode": "248",
  "schengen": false
},
{
  "code": "SL",
  "icaoCode": "SLE",
  "name": "Sierra Leone",
  "phoneCode": "232",
  "schengen": false
},
{
  "code": "SG",
  "icaoCode": "SGP",
  "name": "Singapore",
  "phoneCode": "65",
  "schengen": false
},
{
  "code": "SX",
  "name": "Sint Maarten",
  "icaoCode": "SMX",
  "phoneCode": "721",
  "schengen": false
},
{
  "code": "SK",
  "icaoCode": "SVK",
  "name": "Slovakia",
  "phoneCode": "421",
  "schengen": true
},
{
  "code": "SI",
  "icaoCode": "SVN",
  "name": "Slovenia",
  "phoneCode": "386",
  "schengen": true
},
{
  "code": "SB",
  "icaoCode": "SLB",
  "name": "Solomon Islands",
  "phoneCode": "677",
  "schengen": false
},
{
  "code": "SO",
  "icaoCode": "SOM",
  "name": "Somalia",
  "phoneCode": "252",
  "schengen": false
},
{
  "code": "ZA",
  "icaoCode": "ZAF",
  "name": "South Africa",
  "phoneCode": "27",
  "schengen": false
},
{
  "code": "KR",
  "icaoCode": "KOR",
  "name": "South Korea",
  "phoneCode": "82",
  "schengen": false
},
{
  "code": "SS",
  "name": "South Sudan",
  "icaoCode": "SSD",
  "phoneCode": "211",
  "schengen": false
},
{
  "code": "ES",
  "icaoCode": "ESP",
  "name": "Spain",
  "phoneCode": "34",
  "schengen": true
},
{
  "code": "LK",
  "icaoCode": "LKA",
  "name": "Sri Lanka",
  "phoneCode": "94",
  "schengen": false
},
{
  "code": "SD",
  "icaoCode": "SDN",
  "name": "Sudan",
  "phoneCode": "249",
  "schengen": false
},
{
  "code": "SR",
  "icaoCode": "SUR",
  "name": "Suriname",
  "phoneCode": "597",
  "schengen": false
},
{
  "code": "SZ",
  "icaoCode": "SWZ",
  "name": "Swaziland",
  "phoneCode": "268",
  "schengen": false
},
{
  "code": "SE",
  "icaoCode": "SWE",
  "name": "Sweden",
  "phoneCode": "46",
  "schengen": true
},
{
  "code": "CH",
  "icaoCode": "CHE",
  "name": "Switzerland",
  "phoneCode": "41",
  "schengen": true
},
{
  "code": "SY",
  "icaoCode": "SYR",
  "name": "Syria",
  "phoneCode": "963",
  "schengen": false
},
{
  "code": "TW",
  "icaoCode": "TWN",
  "name": "Taiwan",
  "phoneCode": "886",
  "schengen": false
},
{
  "code": "TJ",
  "icaoCode": "TJK",
  "name": "Tajikistan",
  "phoneCode": "992",
  "schengen": false
},
{
  "code": "TZ",
  "icaoCode": "TZA",
  "name": "Tanzania",
  "phoneCode": "255",
  "schengen": false
},
{
  "code": "TH",
  "icaoCode": "THA",
  "name": "Thailand",
  "phoneCode": "66",
  "schengen": false
},
{
  "code": "TG",
  "icaoCode": "TGO",
  "name": "Togo",
  "phoneCode": "0",
  "schengen": false
},
{
  "code": "TO",
  "icaoCode": "TON",
  "name": "Tonga",
  "phoneCode": "676",
  "schengen": false
},
{
  "code": "TT",
  "icaoCode": "TTO",
  "name": "Trinidad and Tobago",
  "phoneCode": "868",
  "schengen": false
},
{
  "code": "TN",
  "icaoCode": "TUN",
  "name": "Tunisia",
  "phoneCode": "216",
  "schengen": false
},
{
  "code": "TR",
  "icaoCode": "TUR",
  "name": "Turkish Republic",
  "phoneCode": "90",
  "schengen": false
},
{
  "code": "CP",
  "icaoCode": "KTC",
  "name": "Turkish Republic of Northern Cyprus",
  "phoneCode": "90",
  "schengen": false
},
{
  "code": "TM",
  "icaoCode": "TKM",
  "name": "Turkmenistan",
  "phoneCode": "993",
  "schengen": false
},
{
  "code": "TC",
  "icaoCode": "TCA",
  "name": "Turks And Caicos Islands",
  "phoneCode": "649",
  "schengen": false
},
{
  "code": "TV",
  "icaoCode": "TUV",
  "name": "Tuvalu",
  "phoneCode": "688",
  "schengen": false
},
{
  "code": "VI",
  "icaoCode": "VIR",
  "name": "US Virgin Islands",
  "phoneCode": "284",
  "schengen": false
},
{
  "code": "UG",
  "icaoCode": "UGA",
  "name": "Uganda",
  "phoneCode": "256",
  "schengen": false
},
{
  "code": "UA",
  "icaoCode": "UKR",
  "name": "Ukraine",
  "phoneCode": "380",
  "schengen": false
},
{
  "code": "AE",
  "icaoCode": "ARE",
  "name": "United Arab Emirates",
  "phoneCode": "971",
  "schengen": false
},
{
  "code": "US",
  "icaoCode": "USA",
  "name": "United States",
  "phoneCode": "1",
  "schengen": false
},
{
  "code": "UY",
  "icaoCode": "URY",
  "name": "Uruguay",
  "phoneCode": "598",
  "schengen": false
},
{
  "code": "UZ",
  "icaoCode": "UZB",
  "name": "Uzbekistan",
  "phoneCode": "998",
  "schengen": false
},
{
  "code": "VU",
  "icaoCode": "VUT",
  "name": "Vanuatu",
  "phoneCode": "678",
  "schengen": false
},
{
  "code": "VA",
  "icaoCode": "VAT",
  "name": "Vatican City State",
  "phoneCode": "0",
  "schengen": false
},
{
  "code": "VE",
  "icaoCode": "VEN",
  "name": "Venezuela",
  "phoneCode": "58",
  "schengen": false
},
{
  "code": "VN",
  "icaoCode": "VNM",
  "name": "Vietnam",
  "phoneCode": "84",
  "schengen": false
},
{
  "code": "WF",
  "icaoCode": "WLF",
  "name": "Wallis and Futuna",
  "phoneCode": "681",
  "schengen": false
},
{
  "code": "YE",
  "icaoCode": "YEM",
  "name": "Yemen",
  "phoneCode": "967",
  "schengen": false
},
{
  "code": "ZR",
  "name": "Zaire",
  "icaoCode": "ZAR",
  "phoneCode": "0",
  "schengen": false
},
{
  "code": "ZM",
  "icaoCode": "ZMB",
  "name": "Zambia",
  "phoneCode": "260",
  "schengen": false
},
{
  "code": "ZW",
  "icaoCode": "ZWE",
  "name": "Zimbabwe",
  "phoneCode": "263",
  "schengen": false
}
]
