import { Component, effect, EffectRef, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, signal, SimpleChanges, WritableSignal } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FormTexts } from './form.model';

@Component({
  selector: 'app-form',
  templateUrl: './form.component.html',
  styleUrl: './form.component.css'
})
export class FormComponent implements OnInit, OnDestroy, OnChanges {


  // INPUTS 

  // REQUIRED
  @Input({ required: true })
  public title!:       string;

  @Input({ required: true })
  public form!:        FormGroup;

  @Input({ required: true })
  public showButtons!: boolean;

  // OPTIONAL
  @Input()
  public canSubmit!:  boolean | undefined;

  @Input()
  public hideEdit:    boolean = false;
  
  @Input()
  public formText!:   FormTexts | undefined;
  
  // OUTPUTS
  @Output()
  public submit:      EventEmitter<void> = new EventEmitter<void>();

  @Output()
  public dismiss:     EventEmitter<void> = new EventEmitter<void>();


  // STATE

  /**
   * Señal que indica si el formulario se puede editar o no.
   * Empieza siendo null y en base al Init se establece a true o false.
   */
  public canEdit: WritableSignal<boolean | null> = signal(null);
  
  
  // GETTERS
  /**
   * Getter que indica si el formulario se puede enviar o no.
   * Si se introduce un canSubmit, se comprueba tambien su valor junto a si el modo de edición está activo.
   */
  protected get _canSubmit(): boolean {
    return this.canSubmit ? 
      this.canSubmit && this.canEdit()! :
      (this.form.valid && !this.form.pristine) && this.canEdit()!;
  }

  // TEMPLATE VARS
  /**
   * Getter que devuelve los textos del formulario.
   * Si no se han introducido textos, se establecen unos por defecto.
   */
  public get templateVars(): FormTexts {
    return this.formText ??
      {
        edit:     'Edit',
        submit:   'Submit',
        dismiss:  'Dismiss'
      }
  }
  
  // LIFE CYCLE HOOKS
  ngOnInit(): void {
    this.initComponent();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes['form'] && !changes['form'].firstChange){
      this.onFormChange();
    }
  }

  ngOnDestroy(): void {
    this.destroyComponent();
  }

  // METHODS
  /**
   * Método que cambia el valor de la señal canEdit.
   * Se puede introducir un valor o no. Si no se introduce, actua como un toggle.
   * @param value 
   */
  public toggleEdit(value?: boolean): void {
    value ? this.canEdit.set(value) : this.canEdit.update(x => !x);
  }

  /**
   * Emite el evento dismiss para ser recogido por el componente padre.
   * Por defecto, se desactiva el modo de edición.
   */
  protected onDismiss(): void {
    this.toggleEdit(false);
    this.dismiss.emit();
  }
  
  // EFFECTS
  protected changeFormState: EffectRef = effect((): void  => {
    if(this.canEdit() !== null){

      this.canEdit() ? this.form.enable() : this.form.disable();

    }
  });

  // PRIVATE METHODS
  /**
   * Inicializa el componente.
   * Si showButtons es true, se desactiva el modo de edición.
   */
  private initComponent(): void {
    this.showButtons ? this.canEdit.set(false) : this.canEdit.set(true);
  }
  
  /**
   * Se destruye el efecto changeFormState.
   */
  private destroyComponent(): void {
    this.changeFormState.destroy();
  }

  /**
   * Método que se ejecuta cuando se cambia el formulario.
   * Si el modo de edición está activo, se habilita el formulario
   * Si no, se deshabilita.
   **/
  private onFormChange(): void {
    if(this.canEdit())
      this.form.enable();
    else
      this.form.disable();
  }
}
