import { Component, effect, EffectRef, Input, OnInit, signal, WritableSignal } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { TdcInfo } from 'src/app/shared/models/user.model';
import { DataProtectionModel } from './personal-data-protection.model';

@Component({
  selector: 'app-personal-data-protection',
  templateUrl: './personal-data-protection.component.html',
  styleUrl: './personal-data-protection.component.css'
})
export class PersonalDataProtectionComponent implements OnInit {
  
  // INPUTS
  @Input()
  public dataProtection!: TdcInfo['data_protection'][0] | undefined;

  // TEMPLATE VARS
  public templateVars: {title: string, text1: string, text2: string} = {
    title: 'Data Protection',
    text1: `
      <b>Chelsea Fc</b>
      <br>
      Please let us know if you would like to be contacted by Chelsea FC and its group companies with news and promotional 
      information about Chelsea FC, its official sponsors and partners (<a href="www.chelseafc.com/partners" target="_blank">www.chelseafc.com/partners</a>)
      and their products and services where we have the relevant contact details:
    `,
    text2: `
      Please read our Privacy Policy (<a href="www.chelseafc.com/privacy" target="_blank">www.chelseafc.com/privacy</a>) to learn more about Chelsea FC and its group companies, 
      how we use your informationand how to change your mind about receiving marketing from us.
    `,
  }
  
  // FORM VARS
  public dataProtectionForm: FormGroup<DataProtectionModel['form']> = new FormGroup<DataProtectionModel['form']>({
    email: new FormControl<boolean>(false),
    mail:  new FormControl<boolean>(false),
    phone: new FormControl<boolean>(false),
    sms:   new FormControl<boolean>(false),
  })

  // LIFE CYCLE HOOKS
  ngOnInit(): void {
    this.initComponent();
  }

  // METHODS
  public setDataProtection(dataProtection: TdcInfo['data_protection'][0]): void {
    this.dataProtectionForm.setValue({
      email: dataProtection.email_channel,
      mail:  dataProtection.mail_channel,
      phone: dataProtection.phone_channel,
      sms:   dataProtection.sms_text_channel,
    })
  }

  public dismissChanges(): void {
    if(this.dataProtection) {
      this.setDataProtection(this.dataProtection!);
    }
  }

  private initComponent(): void {

   this.dataProtection ? this.setDataProtection(this.dataProtection) : null;

  }
}
